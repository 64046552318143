import 'scroll-behavior-polyfill'
import Scroll from './global/Scroll'
import Header from './global/Header'
import Drawer from './global/Drawer'
import SlideIn from './libs/effects/slideIn'

/** Scroll */
const scroll = new Scroll()

/** Header */
const header = new Header()

/** Drawer */
const drawer = new Drawer({ scroll: scroll })
window.drawer = drawer

/** Slide In */
new SlideIn('.js-slideIn')
/** Fade In */
new SlideIn('.js-fadeIn')

/** Slide Screen */
new SlideIn('.js-slideScreen')

// MV Animation
const targets = document.querySelectorAll(".js-mv-anim");
const mvAnim = (entries) => {
  entries.forEach((entry) => {
    entry.target.classList.add("-is-anim");
  });
};
const observer = new IntersectionObserver(mvAnim);
targets.forEach((i) => {
  observer.observe(i);
});
