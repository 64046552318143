/**
 * Header class
 */

import { throttle } from '../libs/utils/throttle';

export default class Header {

  constructor() {
    this.$header = document.getElementById('header')
    this.$menu = document.getElementById('header-menu')
    this.$menuToggles = document.querySelectorAll('.header-nav-list > .-has-menu')
    this.$menuItems = document.querySelectorAll('.header-menu-i')

    // show hide by scroll
    this.scrollBefore = window.scrollY || window.pageYOffset
    const observeVisibility = () => {
      const scrollAfter = window.scrollY || window.pageYOffset
      if (scrollAfter <= 0 || scrollAfter < this.scrollBefore) {
        this.show()
      } else {
        if (scrollAfter > 400) this.hide()
      }
      this.scrollBefore = scrollAfter;
    }
    window.addEventListener('scroll', throttle(observeVisibility, 50))

    // init submenu height
    const submenus = document.querySelectorAll('.header-nav-list-sub')
    submenus.forEach(submenu => {
      submenu.style.height = submenu.scrollHeight + 'px'
    })
  }

  hide() {
    this.$header.classList.add('-hidden')
  }

  show() {
    this.$header.classList.remove('-hidden')
  }

  showMenu() {
    this.$header.classList.add('-menu-shown')
    this.$menu.classList.add('-shown')
  }

  hideMenu() {
    this.$header.classList.remove('-menu-shown')
    this.$menu.classList.remove('-shown')
    this.hideCurrentMenu()
  }

  showMenuItem(e) {
    const $target = e.target
    if (!$target) return
    const menuName = $target.getAttribute('data-menu')
    if (!menuName) return
    this.hideCurrentMenu()
    const $targetMenu = document.querySelector(`.header-menu-i[data-menu="${menuName}"`)
    $targetMenu.classList.add('-current')
    this.$menu.setAttribute('data-menu', menuName)
  }

  hideCurrentMenu() {
    const $currentMenu = document.querySelector('.header-menu-i.-current')
    if (!$currentMenu) return
    $currentMenu.classList.remove('-current')
    this.$menu.setAttribute('data-menu', '')
  }
}
