/**
 * Slide In Effect Class
 */

export default class SlideIn {

  constructor(selector) {
    this.selector = selector
    this.targets = document.querySelectorAll(selector)

    this.targets.forEach(target => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const delay = target.getAttribute('data-delay') || 0
            setTimeout(() => {
              entry.target.classList.add('is-shown')
            }, delay)
          }
        })
      }, {
        rootMargin: '0px 0px -10%'
      })
      observer.observe(target)
    })
  }
}
